<script>
export default {
  methods: {
    onStartPlayAudio(el, index) {
      console.log("el", el);
      if (Number.isInteger(this.$options.currentlyPlayingAudioIndex)) {
        this.$refs.playAudio[this.$options.currentlyPlayingAudioIndex].pause();
      }
      this.$options.currentlyPlayingAudioIndex = index;
    },
  },
  render() {
    const { onStartPlayAudio } = this;
    return this.$scopedSlots.default({
      onStartPlayAudio,
    });
  },
};
</script>

<style lang="scss" scoped>
</style>