<template>
  <div @click.prevent="onAudioClick" class="media_player">
    <div class="media-image-container">
      <img class="desktop-media media-image" v-bind:src="imgSrc" />
    </div>
    <audio ref="audio" loop v-bind:src="audioSrc" />
  </div>
</template>

<script>
import Pause from "@/assets/img/pause.svg";
import Play from "@/assets/img/desktop-play.svg";

export default {
  props: {
    audioSrc: String,
  },
  data() {
    return {
      isPlaying: false,
    };
  },
  computed: {
    imgSrc() {
      return this.isPlaying ? Pause : Play;
    },
  },
  methods: {
    onAudioClick() {
      this.isPlaying ? this.pause() : this.start();
    },
    start() {
      this.isPlaying = true;
      this.$refs.audio.play();
      this.$emit("play", this.$refs.audio);
    },
    pause() {
      this.isPlaying = false;
      this.$refs.audio.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
.media_player {
  position: relative;
  cursor: pointer;
}

.media-image-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 30px;
}

.media-image {
  max-width: 100%;
  display: block;
  height: auto;
  max-height: 100%;
}
</style>