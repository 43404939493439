<template>
  <div class="view">
    <div v-if="apiStatus === API_STATUS.PENDING">Loading data...</div>

    <div v-else-if="apiStatus === API_STATUS.ERROR">
      There was a problem while fetching data
    </div>

    <template v-else-if="apiStatus === API_STATUS.SUCCESS">
      <router-link to="/" class="back"
        ><img
          class="desktop_form_close"
          src="@/assets/img/close.svg"
          alt="close"
      /></router-link>

      <template v-if="!details">
        No data found. Would you like to try another record?
      </template>
      <template v-else>
        <div class="group">
          <label><strong>Production:</strong></label>
          <h1>{{ details.production }}</h1>
        </div>

        <div class="headings__container group hide-on-tablet">
          <div class="production-heading">
            <label class="inline"><strong>Producer: </strong></label>
            {{ details.producer }}
          </div>
          <div class="artist-heading">
            <label class="inline"><strong>Type:</strong></label>
            {{ details.type }}
          </div>
          <div class="date-heading">
            <label class="inline"><strong>Style:</strong></label>
            {{ details.style }}
          </div>
        </div>

        <div class="headings__container">
          <span class="production-heading"
            >Work used ({{ productionsCount }})</span
          >
          <span class="artist-heading"> Performer</span>
          <span class="date-heading">Date</span>
        </div>

        <section class="details__section">
          <div
            class="details__row"
            v-for="item in production"
            v-bind:key="item.objectID"
          >
            <div class="production-name__col">
              <router-link
                class="production-name"
                v-bind:to="'/details/' + item.mtw_id"
              >
                {{ item.track }}
              </router-link>
            </div>

            <div class="artist-name__col">
              <router-link
                class="artist-name"
                :to="`/artist/${item.artist_id}?artist_name=${item.artist_name}`"
              >
                {{ item.artist_name }}
              </router-link>
            </div>

            <div class="date-name__col">
              <span class="date-name">
                {{ item.date }}
              </span>
            </div>
          </div>
          <LoadMore @loadMore="loadMore">
            <p v-if="loadMoreStatus === API_STATUS.PENDING">
              Fetching more data...
            </p>
          </LoadMore>
        </section>
      </template>
    </template>
  </div>
</template>

<script>
import { syncIndex } from "@/services/algoliaService.js";
import { API_STATUS } from "@/constants/apiStatus.js";
import { LoadMore } from "@/components/common";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  name: "Production",
  components: {
    LoadMore,
  },
  metaInfo: {
    title: "Production",
  },
  props: {
    production_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      details: null,
      production: [],
      apiStatus: IDLE,
      loadMoreStatus: IDLE,
      production_id_facets: null,
    };
  },
  computed: {
    productionsCount() {
      if (!this.production_id_facets) return 0;
      return Object.values(this.production_id_facets).reduce(
        (acc, count) => acc + count,
        0
      );
    },
  },
  methods: {
    async loadMore() {
      if (this.loadMoreStatus === PENDING || this.$options.isLastPage) return;

      try {
        this.loadMoreStatus = PENDING;
        this.$options.page++;
        const result = await this.fetchData(
          this.production_id,
          this.$options.page
        );
        console.log({ result });
        const { hits } = result;
        if (hits.length) {
          this.production = [...this.production, ...hits];
        } else {
          this.$options.isLastPage = true;
        }
        this.loadMoreStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.loadMoreStatus = ERROR;
      }
    },
    fetchData(production_id, page) {
      return syncIndex.search("", {
        filters: `production_id:${production_id}`,
        facets: ["production_id"],
        page,
        distinct: false,
      });
    },
    async fetchProductionDetails(production_id, page) {
      try {
        if (this.apiStatus === API_STATUS.PENDING) return;
        this.apiStatus = API_STATUS.PENDING;

        const result = await this.fetchData(production_id, page);

        if (result.hits.length) {
          const [{ producer, production, type, style, country }] = result.hits;
          this.details = {
            producer,
            production,
            type,
            style,
            country,
          };
        } else {
          this.$options.isLastPage = true;
        }

        this.production = [...this.production, ...result.hits];
        this.production_id_facets = result.facets.production_id;
        this.apiStatus = API_STATUS.SUCCESS;
      } catch (error) {
        console.error(error);
        this.apiStatus = API_STATUS.ERROR;
      }
    },
  },
  created() {
    this.$options.page = 0;
    this.$options.isLastPage = false;

    this.API_STATUS = API_STATUS;
    if (!this.production_id) this.$router.push("/");
    this.fetchProductionDetails(this.production_id, this.$options.page);
  },
};
</script>

<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.details__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 220px;
  }
}

.details__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "publisher"
      "admin";
  }

  @include tablet {
    display: flex;
  }
}

.artist {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    color: hsla(204, 4%, 23%, 1);
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }
  }

  &-name__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 40%;
    }
  }
}

.production {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: publisher;
    @include tablet {
      flex-basis: 40%;
    }
  }
}

.date {
  &-heading {
    flex-basis: 20%;
  }

  &__col {
    grid-area: admin;
    @include tablet {
      flex-basis: 20%;
    }
  }

  &-name {
    @include mobile {
      color: $color-primary;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>