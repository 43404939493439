<template>
  <component :is="tag" style="height: 1px">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
  beforeDestroy() {
    this.observer.unobserve(this.$el);
  },
  mounted() {
    const el = this.$el;
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // In that case we can refine
          this.$emit("loadMore");
        }
      });
    });

    this.observer.observe(el);
  },
};
</script>

<style lang="scss" scoped>
</style>