<template>
  <div>
    <router-link to="/" class="back close-button"
      ><img class="desktop_form_close" src="@/assets/img/close.svg" alt="close"
    /></router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prevRoute: null,
    };
  },

  created() {
    console.log("Prev route", this);
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  display: none;
  @include tablet {
    display: block;
  }
}
.back-button {
  @include tablet {
    display: none;
  }
}
</style>